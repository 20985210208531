var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-card',{staticClass:"white-bg",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-secondary","to":{ name: 'ExpenseIndex' }}},[_c('font-awesome-icon',{attrs:{"icon":"angle-left"}}),_vm._v(" Regresar ")],1),(_vm.expense.id > 0)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"secondary","to":{ name: 'ExpenseShow', params: { id: _vm.expense.id }}}},[_c('font-awesome-icon',{attrs:{"icon":"search"}}),_vm._v(" Detalle ")],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","form":"user-form","variant":"primary"}},[_c('font-awesome-icon',{attrs:{"icon":"save"}}),_vm._v(" Guardar")],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{attrs:{"id":"user-form"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveExpense)}}},[_c('h5',[_vm._v("Datos generales")]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descripción","invalid-feedback":"Ingresa la descripción"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Descripción","state":errors[0] ? false : null},model:{value:(_vm.expense.name),callback:function ($$v) {_vm.$set(_vm.expense, "name", $$v)},expression:"expense.name"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cantidad","invalid-feedback":"Ingresa la cantidad"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Cantidad","state":errors[0] ? false : null},model:{value:(_vm.expense.qty),callback:function ($$v) {_vm.$set(_vm.expense, "qty", $$v)},expression:"expense.qty"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Precio unitario","invalid-feedback":"Ingresa el precio unitario"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Precio unitario","state":errors[0] ? false : null},model:{value:(_vm.expense.unitPrice),callback:function ($$v) {_vm.$set(_vm.expense, "unitPrice", $$v)},expression:"expense.unitPrice"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Total","invalid-feedback":"Ingresa el total"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Total","disabled":""},model:{value:(_vm.expense.total),callback:function ($$v) {_vm.$set(_vm.expense, "total", $$v)},expression:"expense.total"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Proveedor","invalid-feedback":"Selecciona el proveedor","state":errors[0] ? false : null}},[_c('v-select',{attrs:{"options":_vm.businessEntities,"placeholder":"Proveedor","label":"legalName","clearable":false,"reduce":function (supplier) { return supplier.id; }},on:{"search:focus":_vm.getBusinessEntities,"search":_vm.getBusinessEntities},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("Sin resultados.")]},proxy:true}],null,true),model:{value:(_vm.expense.supplierId),callback:function ($$v) {_vm.$set(_vm.expense, "supplierId", $$v)},expression:"expense.supplierId"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de pago"}},[_c('b-form-radio-group',{attrs:{"options":_vm.paymentTypes,"state":errors[0] ? false : null},model:{value:(_vm.expense.paymentType),callback:function ($$v) {_vm.$set(_vm.expense, "paymentType", $$v)},expression:"expense.paymentType"}},[_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v("Ingresa el tipo de pago")])],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Notas"}},[_c('b-form-textarea',{attrs:{"autocomplete":"false","placeholder":"Notas"},model:{value:(_vm.expense.notes),callback:function ($$v) {_vm.$set(_vm.expense, "notes", $$v)},expression:"expense.notes"}})],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }