<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveExpense)" id="user-form">
                    <h5>Datos generales</h5>
                    <b-row class="mb-2">
                        <b-col cols="12" md="12">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Descripción" invalid-feedback="Ingresa la descripción">
                                    <b-form-input v-model="expense.name" autocomplete="false" placeholder="Descripción"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Cantidad" invalid-feedback="Ingresa la cantidad">
                                    <b-form-input v-model="expense.qty" autocomplete="false" placeholder="Cantidad"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Precio unitario" invalid-feedback="Ingresa el precio unitario">
                                    <b-form-input v-model="expense.unitPrice" autocomplete="false" placeholder="Precio unitario"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group label="Total" invalid-feedback="Ingresa el total">
                                <b-form-input v-model="expense.total" autocomplete="false" placeholder="Total" disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Proveedor" invalid-feedback="Selecciona el proveedor" :state="errors[0] ? false : null">
                                    <v-select :options="businessEntities" @search:focus="getBusinessEntities" @search="getBusinessEntities"
                                              v-model="expense.supplierId" placeholder="Proveedor" label="legalName" :clearable="false"
                                              :reduce="supplier => supplier.id">
                                        <template #no-options>Sin resultados.</template>
                                    </v-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Tipo de pago">
                                    <b-form-radio-group v-model="expense.paymentType" :options="paymentTypes" :state="errors[0] ? false : null">
                                        <b-form-invalid-feedback :state="errors[0] ? false : null">Ingresa el tipo de pago</b-form-invalid-feedback>
                                    </b-form-radio-group>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="12">
                            <b-form-group label="Notas">
                                <b-form-textarea v-model="expense.notes" autocomplete="false" placeholder="Notas"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'ExpenseIndex' }" class="mr-2">
                        <font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar
                    </b-button>
                    <b-button variant="secondary" :to="{ name: 'ExpenseShow', params: { id: expense.id }}" v-if="expense.id > 0" class="mr-2">
                        <font-awesome-icon icon="search"></font-awesome-icon> Detalle
                    </b-button>
                    <b-button type="submit" form="user-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required, integer } from 'vee-validate/dist/rules';
import { businessEntityTypeUrl, expenseUrl } from '../../js/routes';
import * as constants from '@constants';
import { Expense } from '../../models/expense';
import Utilities from '../../js/utilities';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('integer', {
    ...integer,
    message: 'Campo requerido'
});

export default {
    data() {
        return {
            expense: new Expense(0, null, null, '', 'GENERAL', '', '', '', '', '', '', '', '', '', '', false, false),
            lastExpense: null,
            swalTitle: 'Gastos',
            breadcrumb: {
                title: 'Gastos',
                path: [
                    {
                        name: 'ExpenseIndex',
                        text: 'Gastos'
                    }
                ]
            },
            businessEntities: [],
            paymentTypes: [
                { text: 'Contado', value: 'CASH' },
                { text: 'Crédito', value: 'CREDIT' }
            ],
            action: 'Nuevo',
            isLoading: false
        };
    },
    created() {
        if (this.$route.name.includes('Edit')) {
            this.loadData();

            this.action = 'Modificar';
        }

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `${this.action}`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(expenseUrl(this.$route.params.id), { params: { calledFrom: 'ExpenseForm' }}).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.expense = Expense.createFromObject(response.data.expense);

                    if (response.data.supplier) {
                        this.businessEntities = [response.data.supplier];
                    }
                }
            }).catch(error => {
                if (error.response.status === 403) {
                    this.$root.$bvToast.toast(error?.response?.data?.message ?? 'No tienes permiso para realizar esta acción', {
                        title: this.swalTitle,
                        variant: 'danger'
                    });

                    this.$router.push({ name: 'ExpenseShow', params: { id: this.$route.params.id }});
                } else {
                    this.$root.$bvToast.toast(response.data.message ?? 'No se pudo obtener la información del gasto', {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        getBusinessEntities(search) {
            this.axios.get(businessEntityTypeUrl(), { params: {
                search,
                entityType: 'SUPPLIER'
            }}).then(response => {
                this.businessEntities = response.data;
            }).catch(() => {
                this.businessEntities = [];
            });
        },
        saveExpense() {
            this.isLoading = true;

            this.axios.post(expenseUrl(), this.expense).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message ?? 'El gasto ha sido guardado', {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    if ('id' in this.$route.params) {
                        this.$router.push({ name: 'ExpenseShow', params: { id: this.expense.id }});
                    } else {
                        this.expense.id = response.data.expense.id;

                        let title = '¿Qué desea hacer?';

                        this.$swal({
                            title: title,
                            icon: 'question',
                            confirmButtonText: 'Ir al detalle del gasto',
                            cancelButtonText: 'Ingresar un nuevo gasto'
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.$router.push({ name: 'ExpenseShow', params: { id: this.expense.id }});
                            } else {
                                this.lastExpense = Expense.createFromObject(this.expense);
                                this.expense = new Expense(0, null, null, '', 'GENERAL', '', '', '', '', '', '', '', '', '', '', false, false);

                                this.$nextTick(() => {
                                    this.$refs.observer.reset();
                                });
                            }
                        });
                    }
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast(error?.response?.data?.message ?? 'Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
    },
    watch: {
        'expense.qty'() {
            let qty = Utilities.isPositiveNumber(this.expense.qty) ? parseFloat(this.expense.qty) : 0;
            let unitPrice = Utilities.isPositiveNumber(this.expense.unitPrice) ? parseFloat(this.expense.unitPrice) : 0;

            this.expense.total = (qty * unitPrice).toFixed(2);
        },
        'expense.unitPrice'() {
            let qty = Utilities.isPositiveNumber(this.expense.qty) ? parseFloat(this.expense.qty) : 0;
            let unitPrice = Utilities.isPositiveNumber(this.expense.unitPrice) ? parseFloat(this.expense.unitPrice) : 0;

            this.expense.total = (qty * unitPrice).toFixed(2);
        }
    }
};
</script>

<style scoped>
</style>